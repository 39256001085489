import { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import useSWRMutation from 'swr/mutation';

import { EEditProfileWorkFieldArrayName } from '../../../../1_shared/config/enums/EFieldArrayNames';
import { TInputArrayField } from '../../../../1_shared/config/types/TInputArrayField';
import { Button as UiButton, Typography } from '../../../../1_shared/ui';
import SpecialistEditProfileCheckbox from '../../../../1_shared/ui/SpecialistEditProfile/SpecialistEditProfileCheckbox';
import SpecialistEditProfileInput from '../../../../1_shared/ui/SpecialistEditProfile/SpecialistEditProfileInput';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import { userObjectConverterWorkExperience } from '../../helpers/userObjectConverter';
import { editProfileWorkExperience } from '../../model/service/specEditService';

import styles from './SpecialistEditProfileWorkExperience.module.scss';

const arrayFieldNames: TInputArrayField[] = [
  { name: 'company', label: 'Компания', required: true },
  { name: 'position', label: 'Должность', required: true },
  { name: 'town', label: 'Город', required: true },
];

const dateArray: TInputArrayField[] = [
  { name: 'date_from', label: 'Период работы (год)', placeholder: 'с' },
  { name: 'date_to', placeholder: 'до' },
];

const SpecialistEditProfileWorkExperience = () => {
  const { user } = useAuthContext();
  // TODO: типизация формы
  const methods = useForm({});
  const { control, handleSubmit, watch, reset } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: EEditProfileWorkFieldArrayName,
  });
  watch();

  const { trigger, isMutating } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileWorkExperience,
  );

  // TODO: any заменить согласно типу формы
  const onSubmit = async (data: any) => {
    await trigger({
      job_experiences: data.job_experiences?.map((element: any) => ({
        ...element,
        date_to: element?.is_present_time ? undefined : element?.date_to,
      })),
    });
  };

  useEffect(() => {
    // TODO: delete any
    reset(userObjectConverterWorkExperience(user));
  }, [user]);

  return (
    <FormProvider {...methods}>
      <Typography type="title">Опыт работы</Typography>
      <div className={styles.wrapper}>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.fieldWrapper}>
            <div className={styles.field}>
              <div className={styles.workExperience}>
                {arrayFieldNames.map((customField: TInputArrayField) => (
                  <SpecialistEditProfileInput
                    {...field}
                    label={customField.label}
                    name={`${EEditProfileWorkFieldArrayName}.${index}.${customField.name}`}
                    required={customField.required}
                  />
                ))}
                <div className={styles.dateBlock}>
                  <SpecialistEditProfileInput
                    {...field}
                    name={`${EEditProfileWorkFieldArrayName}.${index}.${dateArray[0].name}`}
                    label={dateArray[0].label}
                    placeholder={dateArray[0].placeholder}
                    type="number"
                  />
                  <div className={styles.text}>—</div>
                  <SpecialistEditProfileInput
                    {...field}
                    name={`${EEditProfileWorkFieldArrayName}.${index}.${dateArray[1].name}`}
                    label={dateArray[1].label}
                    placeholder={dateArray[1].placeholder}
                    disabled={methods.getValues(
                      `${EEditProfileWorkFieldArrayName}.${index}.is_present_time` as any,
                    )}
                    type="number"
                  />
                </div>
                <SpecialistEditProfileCheckbox
                  name={`${EEditProfileWorkFieldArrayName}.${index}.is_present_time`}
                  label="Работаю в настоящий момент"
                />
              </div>
              <Button
                className={styles.removeButton}
                type="link"
                onClick={() => {
                  remove(index);
                }}
              >
                <CloseOutlined />
              </Button>
            </div>
          </div>
        ))}
      </div>
      <Button
        type="link"
        className={styles.addButton}
        onClick={() => append({})}
      >
        <PlusOutlined className={styles.icon} />
        Добавить место работы
      </Button>
      {!!fields?.length && (
        <UiButton
          className={styles.saveButton}
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isMutating}
        >
          Сохранить
        </UiButton>
      )}
    </FormProvider>
  );
};

export default SpecialistEditProfileWorkExperience;
