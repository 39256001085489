import { UploadFile } from 'antd';
import * as yup from 'yup';

import { IFirstFormData } from '../interfaces/IFirstFormData';

import { userInfoSchema } from './userInfoSchema';

export const firstFormTabsSchema = yup.object().shape({
  avatar: yup.mixed<UploadFile>(),
  mediaFiles: yup.array().of(yup.mixed<UploadFile>().required()),
  data: yup
    .object<IFirstFormData>()
    .shape({
      userInfo: userInfoSchema,
      startWorkDate: yup.string().required(),
      specialities: yup.array().of(yup.string()).required(),
      workWiths: yup.array().of(yup.string()).required(),
      workMethods: yup.array().of(yup.string()).required(),
      keyThemes: yup.array().of(yup.string()).required(),
      about: yup.string().required('sometext'),
    })
    .required(),
});
