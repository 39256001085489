import { useLocation, useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { ERoles } from '1_shared/config/enums/ERoles';
import { ESpecStatus } from '1_shared/config/enums/ESpecStatus';
import { RoutePath } from '1_shared/config/routes';

import { profileSpec } from './api/auth.service';

const useSpecDetect = (): ((role: ERoles) => Promise<void>) => {
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { trigger: checkProfile } = useSWRMutation(
    '/spec/specialist/profile',
    profileSpec,
    {
      onSuccess: data => {
        if (data.status === ESpecStatus.NotActive) {
          navigate(RoutePath.FIRST_FORM);
        }
        if (
          data.status !== ESpecStatus.NotActive &&
          location.pathname === RoutePath.FIRST_FORM
        ) {
          navigate(RoutePath.MAIN);
        }
      },
    },
  );

  return async (role: ERoles) => {
    if (role === ERoles.Spec) {
      await checkProfile();
    }
  };
};

export default useSpecDetect;
