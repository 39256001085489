import { DefaultOptionType } from 'antd/es/select';

const filterOption = (input: string, option?: DefaultOptionType) => {
  const label = option?.label;
  return (
    typeof label === 'string' &&
    label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
};

export default filterOption;
