import dayjs from 'dayjs';

import { otherInformationFieldArray } from '../../../1_shared/config/enums/EFieldArrayNames';
import { ISpecialistByIdData } from '../../../1_shared/config/interfaces/ISpecialistByIdData';
import { changeNameToCamelCase } from '../../../1_shared/helpers/convertNameToCapitalize';
import { IClientPriviewOutput } from '../../../app/api/interfaces/IClientPriviewOutput';

// TODO: убрать any
type TUserObject = IClientPriviewOutput & ISpecialistByIdData & any;
const filteredPropsForOld = [
  'aboutYourself',
  'academicDegree',
  'birthday',
  'firstName',
  'jobExperiences',
  'keyThemes',
  'media',
  'professionalRetrainings',
  'professionalStatus',
  'secondName',
  'supervisionHours',
  'therapyHours',
  'workSince',
  'workWiths',
  'workMethods',
];

const filteredPropsForNew = [
  'about_yourself',
  'academic_degree',
  'date_of_birth',
  'first_name',
  'job_experiences',
  'key_themes',
  'media',
  'professional_retrainings',
  'professional_status',
  'last_name',
  'supervision_hours',
  'therapy_hours',
  'work_since',
  'work_withs',
  'work_methods',
];

/** TODO: обертка для объекта пользователь
 * нужна для того чтобы модифицировать объект для старой версии бэкенда
 * нужно депрейкейтнуть когда весь бэкенд будет апдейтнут до новой версии
 * // TODO: change backend
 * */
export const userObjectConverterToOld = (user: TUserObject) => ({
  ...(user
    ? Object.fromEntries(
        Object.entries(user).filter(
          elem => !filteredPropsForOld.includes(elem[0]),
        ),
      )
    : []),
  about_yourself: user?.aboutYourself,
  academic_degree: user?.academicDegree,
  date_of_birth: user?.birthday,
  first_name: user?.firstName,
  job_experiences: user?.jobExperiences,
  key_themes: user?.keyThemes,
  media: user?.mediaContentResponse,
  professional_retrainings: user?.professionalRetrainings,
  professional_status: user?.professionalStatus,
  last_name: user?.secondName,
  supervision_hours: user?.supervisionHours,
  therapy_hours: user?.therapyHours,
  work_since: user?.workSince,
  work_withs: user?.workWiths,
  work_methods: user?.workMethods,
});

export const userObjectConverterToNew = (
  user: TUserObject,
): ISpecialistByIdData =>
  ({
    ...(user
      ? Object.fromEntries(
          Object.entries(user)?.filter(
            elem => !filteredPropsForNew.includes(elem[0]),
          ),
        )
      : []),
    aboutYourself: user?.about_yourself,
    academicDegree: user?.academic_degree,
    birthday: user?.date_of_birth,
    firstName: user?.first_name,
    jobExperiences: user?.job_experiences,
    keyThemes: user?.key_themes,
    media: user?.media,
    professionalRetrainings: user?.professional_retrainings,
    professionalStatus: user?.professional_status,
    secondName: user?.last_name,
    supervisionHours: user?.supervision_hours,
    therapyHours: user?.therapy_hours,
    workSince: user?.work_since,
    workWiths: user?.work_withs,
    workMethods: user?.work_methods,
  }) as any;

export const userObjectConverterCredentials = (user: TUserObject) => ({
  first_name: user?.firstName,
  last_name: user?.secondName,
  patronymic: user?.patronymic,
  sex: user?.sex,
  date_of_birth: dayjs(user?.birthday).isValid()
    ? dayjs(user?.birthday).toString()
    : user?.birthday,
  email: user?.email,
  phone: user?.phone,
});

export const userObjectConverterMainInfo = (user: TUserObject) => ({
  work_since: dayjs(user?.workSince).isValid()
    ? dayjs(user?.workSince).format('YYYY')
    : undefined,
  specialities: user?.specialities,
  work_methods: user?.workMethods,
  key_themes: user?.keyThemes,
  work_withs: user?.workWiths,
  about_yourself: user?.aboutYourself,
});

export const userObjectConverterWorkExperience = (user: TUserObject) => ({
  job_experiences: user?.jobExperiences.map((elem: any) => ({
    ...elem,
    date_from: dayjs(elem.date_from).isValid()
      ? dayjs(elem.date_from).format('YYYY')
      : elem.date_from,
  })),
});
export const userObjectConverterEducation = (
  user: TUserObject,
  fieldArrayName: string,
) => (user as any)?.[changeNameToCamelCase(fieldArrayName)];

export const userObjectConverterOtherInformation = (user: TUserObject) => ({
  therapy_hours: user?.therapyHours,
  supervision_hours: user?.supervisionHours,
  professional_status: user?.professionalStatus,
  [otherInformationFieldArray]: user?.achievements,
});
