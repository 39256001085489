import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import useSWRMutation from 'swr/mutation';

import { otherInformationFieldArray } from '../../../../1_shared/config/enums/EFieldArrayNames';
import { IOtherInformation } from '../../../../1_shared/config/interfaces/IOtherInformation';
import { statusOptions } from '../../../../1_shared/constants/statusOptions';
import filterOption from '../../../../1_shared/helpers/filterOption';
import {
  Button as UiButton,
  Input,
  Select,
  Typography,
} from '../../../../1_shared/ui';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
// eslint-disable-next-line import/no-cycle
import { editProfileOtherInformation } from '../../model/service/specEditService';

import styles from './OtherInformation.module.scss';

const OtherInformation = () => {
  const methods = useForm<IOtherInformation>();
  const { control, getValues, setValue, handleSubmit } = methods;
  const { fields, remove, append } = useFieldArray({
    control,
    // @ts-ignore
    name: otherInformationFieldArray,
  });

  const { trigger, isMutating } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileOtherInformation,
  );

  const onSubmit = async (data: IOtherInformation) => {
    await trigger({
      ...data,
    });
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.root}>
        <Typography type="title">Дополнительная информация</Typography>
        <div>
          <Typography type="description">
            Личная терапия/анализ (количество часов)
          </Typography>
          <Controller
            name="therapy_hours"
            control={control}
            defaultValue={getValues('therapy_hours')}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                min={0}
                placeholder="Личная терапия/анализ (количество часов)"
              />
            )}
          />
        </div>
        <div>
          <Typography type="description">
            Супервизия (количество часов)
          </Typography>
          <Controller
            name="supervision_hours"
            control={control}
            defaultValue={getValues('supervision_hours')}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                min={0}
                placeholder="Супервизия (количество часов)"
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Статус (педагог, супервизор, профессор)
          </Typography>
          <Controller
            control={control}
            name="professional_status"
            render={({ field }) => (
              <Select
                allowClear
                options={statusOptions}
                showSearch
                filterOption={filterOption}
                {...field}
              />
            )}
          />
        </div>
        <Typography type="textM">Достижения</Typography>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.achievement}>
            <TextArea
              {...field}
              rows={4}
              maxLength={6}
              onChange={event =>
                setValue(
                  `${otherInformationFieldArray}.${index}` as any,
                  event.target.value,
                )
              }
            />
            <Button
              className={styles.removeButton}
              type="link"
              onClick={() => {
                remove(index);
              }}
            >
              <CloseOutlined />
            </Button>
          </div>
        ))}
        <Button
          type="link"
          className={styles.addButton}
          onClick={() => append('')}
        >
          <PlusOutlined className={styles.icon} />
          Добавить достижение
        </Button>
      </div>
      <UiButton
        className={styles.saveButton}
        type="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={isMutating}
      >
        Сохранить
      </UiButton>
    </FormProvider>
  );
};

export default OtherInformation;
