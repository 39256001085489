import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import useSWRMutation from 'swr/mutation';

import { getDictionaries } from '1_shared/api/dictionary';
import useSWRWithCache from '1_shared/api/lib/useSWRWithCache';
import { contactTypeMapping } from '1_shared/config/enums/EContactType';
import { dayTypeMapping } from '1_shared/config/enums/EDayType';
import { expWithPsyMapping } from '1_shared/config/enums/EExpWithPsy';
import { psychoGenderMapping } from '1_shared/config/enums/EPsychoGender';
import { wellBeingStatusMapping } from '1_shared/config/interfaces/EWellBeing';
import { IDictionaryTheme } from '1_shared/config/interfaces/IDictionaryTheme';
import { getUtm } from '1_shared/helpers/getUtm';
import { Button, Tabs } from '1_shared/ui';

import { dayTimeConfig } from '../../../1_shared/config/dayTimeConfig';
import {
  dayTimeMapping,
  EDayTime,
} from '../../../1_shared/config/enums/EDayTime';
import { IAnketaForm } from '../../../1_shared/config/interfaces/IAnketaForm';
import { RoutePath } from '../../../1_shared/config/routes';
import { getSpecialistList } from '../../../5_pages/SpecialistsPage/model/service/specService';
import { env } from '../../../env';
import { itemsForm } from '../config/itemsConfig';
import { schema } from '../config/schema';

import styles from './AnketaTabs.module.scss';

const AnketaTabsForm = () => {
  const { data: dictionaryThemes } = useSWRWithCache(
    '/spec/key-themes',
    getDictionaries,
  );
  const [activeTab, setActiveTab] = useState<string>('1');

  const methods = useForm<IAnketaForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const navigate = useNavigate();
  const goNext = () => {
    setActiveTab(prev => String(Number(prev) + 1));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  const goPrevious = () => {
    setActiveTab(prev => String(Number(prev) - 1));
  };

  const transformKeyThemes = (
    keyThemes: string[],
    dictionaryThemes: IDictionaryTheme[],
  ): Record<string, string | null> => {
    const categories: Record<string, string> = {
      sex_and_gender: 'sexGender',
      self_rating: 'selfRating',
      psychosomatics: 'psychosomatics',
      relationships: 'relationship',
      emotional_state: 'emotional',
      life_events: 'liveEvents',
      motivation: 'motivation',
    };

    const preResult: Record<string, string[]> = {
      sexGender: [],
      selfRating: [],
      psychosomatics: [],
      relationship: [],
      emotional: [],
      liveEvents: [],
      motivation: [],
    };

    keyThemes.forEach(theme => {
      const foundTheme = dictionaryThemes.find(item => item.value === theme);
      if (foundTheme) {
        const category = categories[foundTheme.categoryNameValue];
        if (category) {
          preResult[category].push(foundTheme.label);
        }
      }
    });

    const result: Record<string, string | null> = {};

    Object.keys(preResult).forEach(key => {
      const joinedResult = preResult[key].join(', ');
      result[key] = joinedResult === '' ? null : joinedResult;
    });

    return result;
  };

  const { trigger } = useSWRMutation(
    `${env.NODE_ENV === 'development' ? env.REACT_APP_API_URL : env.REACT_APP_SOCKET_URL}/ns/api/v1/system-notifications/session-form`,
    getSpecialistList,
  );

  const getData = async (data: any) => {
    await trigger(data);
  };

  const onSubmit = async (data: IAnketaForm) => {
    const keyThemes = data.keyThemas
      ? transformKeyThemes(data.keyThemas, dictionaryThemes!)
      : undefined;
    const utm = getUtm();
    const requestData = {
      keyThemes,
      wellBeing: data.wellBeing ? wellBeingStatusMapping[data.wellBeing] : null,
      convenientTime: data.time ? dayTimeMapping[data.time] : null,
      convenientDays: data.dayType ? dayTypeMapping[data.dayType] : null,
      psychologistExpWorking: data.haveExpWithPsy
        ? expWithPsyMapping[data.haveExpWithPsy]
        : null,
      preferredGenderPsycho: data.sex ? psychoGenderMapping[data.sex] : null,
      name: data.name || '',
      phone: data.phone || '',
      preferredContactType: data.chooseContactType
        ? contactTypeMapping[data.chooseContactType]
        : null,
      utm,
    };

    await getData(requestData);

    const tempData = {
      experienceSortOrder: 'DESC',
      slotsFilter: {
        dayType: data.dayType || null,
        ...dayTimeConfig[data.time as EDayTime],
      },
      keyThemes: data.keyThemas || undefined,
      sex: data.sex || null,
    };

    localStorage.removeItem('utm');
    navigate(RoutePath.SPECIALISTS, {
      state: { filters: tempData },
    });
  };

  return (
    <section className={styles.testing__inner}>
      <FormProvider {...methods}>
        <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            classNameTabs={styles.tabs}
            items={itemsForm}
            disabledToggle
            activeTab={activeTab}
            defaultActiveTabIndex="1"
          />
          <footer
            className={cn(styles.btnWrapper, {
              [styles.btnFirstWrap]:
                itemsForm && activeTab === itemsForm[0].key,
            })}
          >
            {itemsForm && activeTab !== itemsForm[0].key && (
              <Button
                className={styles.btnPrevios}
                type="secondary"
                onClick={goPrevious}
              >
                НАЗАД
              </Button>
            )}
            {itemsForm && activeTab !== itemsForm[itemsForm.length - 1].key && (
              <Button
                className={styles.btnNext}
                type="primary"
                onClick={goNext}
              >
                ДАЛЕЕ
              </Button>
            )}
            {itemsForm && activeTab === itemsForm[itemsForm.length - 1].key && (
              <Button
                htmlType="submit"
                className={styles.btnSubmit}
                type="primary"
                disabled={!isValid}
              >
                ПОДОБРАТЬ СПЕЦИАЛИСТА
              </Button>
            )}
          </footer>
        </form>
      </FormProvider>
    </section>
  );
};

export default AnketaTabsForm;
