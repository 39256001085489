import dayjs from 'dayjs';
import * as yup from 'yup';

import { IUserInfo } from '../../api/interfaces/IUserInfo';
import { IUserEditInfo } from '../interfaces/IUserEditInfo';

export const userInfoSchema = yup.object<IUserInfo>().shape({
  firstName: yup.string().required('Имя обязательно для заполнения'),
  surname: yup.string().required('Фамилия обязательна для заполнения'),
  patronymic: yup.string(),
  sex: yup.string().required('Пол обязателен для заполнения'),
  birthday: yup
    .string()
    .required('Дата рождения обязательна для заполнения')
    .test('is-18-or-older', 'Вам должно быть не менее 18 лет', value => {
      if (!value) return false;
      return dayjs().diff(dayjs(value), 'year') >= 18;
    }),
  email: yup
    .string()
    .email('Некорректный email')
    .required('Почта обязательна для заполнения'),
  phone: yup
    .string()
    .matches(
      /\+7\s\d{3}\s\d{3}\s\d{2}\s\d{2}/,
      'Номер должен быть в виде +7 999 999 99 99',
    )
    .required('Телефон обязателен для заполнения'),
});

export const userEditInfoSchema = yup
  .object<IUserEditInfo>()
  .shape({
    avatar: yup.mixed(),
    media: yup.array(),
    first_name: yup.string().required('Имя обязательно для заполнения'),
    last_name: yup.string().required('Фамилия обязательна для заполнения'),
    patronymic: yup.string(),
    sex: yup.string(),
    date_of_birth: yup
      .number()
      .required('Дата рождения обязательна для заполнения'),
    email: yup
      .string()
      .email('Некорректный email')
      .required('Почта обязательна для заполнения'),
    phone: yup
      .string()
      .matches(
        /\+7\s\d{3}\s\d{3}\s\d{2}\s\d{2}/,
        'Номер должен быть в виде +7 999 999 99 99',
      )
      .required('Телефон обязателен для заполнения'),
  })
  .required();
