import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { TInputArrayField } from '../../../../../../1_shared/config/types/TInputArrayField';
import { educationArrayFieldNames } from '../../../../../../1_shared/constants/educationArrayFieldNames';
import { Typography } from '../../../../../../1_shared/ui';
import SpecialistEditProfileInput from '../../../../../../1_shared/ui/SpecialistEditProfile/SpecialistEditProfileInput';
import { useAuthContext } from '../../../../../../app/module/lib/hooks/useAuthContext';
import { userObjectConverterEducation } from '../../../../helpers/userObjectConverter';

import styles from './EducationComponent.module.scss';

const EducationComponent = ({ fieldArrayName }: { fieldArrayName: string }) => {
  const { user } = useAuthContext();

  const fieldProps = (educationArrayFieldNames as any)?.[fieldArrayName];
  const methods = useFormContext();
  const { fields, append, remove, replace } = useFieldArray<any>({
    control: methods.control,
    name: fieldArrayName,
  });

  const max = fieldProps?.max || -1;

  useEffect(() => {
    replace(userObjectConverterEducation(user, fieldArrayName));
  }, []);

  return (
    <div className={styles.root}>
      <Typography type="textM">{fieldProps?.label}</Typography>
      <div className={styles.wrapper}>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.fieldWrapper}>
            <div className={styles.field}>
              <div className={styles.education}>
                {fieldProps?.array.map((field: TInputArrayField) => (
                  <SpecialistEditProfileInput
                    {...field}
                    type={field?.type}
                    name={`${fieldArrayName}.${index}.${field.name}`}
                  />
                ))}
              </div>
              <Button
                className={styles.removeButton}
                type="link"
                onClick={() => {
                  remove(index);
                }}
              >
                <CloseOutlined />
              </Button>
            </div>
          </div>
        ))}
      </div>
      {fieldProps?.addButtonLabel && fields?.length !== max && (
        <Button
          type="link"
          className={styles.addButton}
          onClick={() => append({})}
        >
          <PlusOutlined className={styles.icon} />
          {fieldProps?.addButtonLabel}
        </Button>
      )}
    </div>
  );
};

export default EducationComponent;
