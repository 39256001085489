import { ReactElement, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextArea from 'antd/es/input/TextArea';
import useSWRMutation from 'swr/mutation';

import { userInfoSchema } from '../../../../1_shared/config/validationSchemas/mainInfoSchema';
import filterOption from '../../../../1_shared/helpers/filterOption';
import { Button, Input, Select, Typography } from '../../../../1_shared/ui';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import { userObjectConverterMainInfo } from '../../helpers/userObjectConverter';
import { editProfileMainInfo } from '../../model/service/specEditService';

import { IMainInfo } from './interfaces/IMainInfo';
import useSpecialistEditProfileMainInfo from './model/useSpecialistEditProfileMainInfo';

import styles from '../Credentials/SpecialistEditProfileCredentials.module.scss';

const SpecialistEditProfileMainInfo = (): ReactElement => {
  const { user } = useAuthContext();
  const methods = useForm<IMainInfo>({
    resolver: yupResolver<IMainInfo>(userInfoSchema),
  });
  const { control, handleSubmit, reset, setValue, getValues } = methods;
  const { keyThemasMemo, workWithMemo, workMethodsMemo, specMemo } =
    useSpecialistEditProfileMainInfo();

  const { trigger, isMutating } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileMainInfo,
  );

  const onSubmit = async (data: IMainInfo) => {
    await trigger({
      ...data,
    });
  };

  useEffect(() => {
    reset(userObjectConverterMainInfo(user));
  }, [user]);

  return (
    <FormProvider {...methods}>
      <div className={styles.root}>
        <Typography type="title">Основная информация</Typography>
        <div className={styles.row}>
          <Typography type="description">
            Опыт консультирования с (год)
            <span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="work_since"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Специализация<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="specialities"
            render={({ field }) => (
              <Select
                allowClear
                mode="multiple"
                options={specMemo}
                showSearch
                filterOption={filterOption}
                {...field}
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Работаете по методам
            <span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="work_methods"
            render={({ field }) => (
              <Select
                allowClear
                maxTagCount="responsive"
                mode="multiple"
                options={workMethodsMemo}
                showSearch
                filterOption={filterOption}
                {...field}
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Ключевые темы<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="key_themes"
            render={({ field }) => (
              <Select
                allowClear
                mode="multiple"
                options={keyThemasMemo}
                showSearch
                filterOption={filterOption}
                {...field}
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Работаете с<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="work_withs"
            render={({ field }) => (
              <Select
                allowClear
                mode="multiple"
                options={workWithMemo}
                showSearch
                filterOption={filterOption}
                {...field}
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="textM">О себе</Typography>
          <Typography type="description">
            Напишите в свободной форме о себе.
          </Typography>
          <Typography type="description">
            Вы можете ответить на один из этих вопросов:
          </Typography>
          <Typography type="description">
            1. Почему вы решили стать психологом
          </Typography>
          <Typography type="description">
            2. Что для вас психотерапия
          </Typography>
          <Typography type="description">
            3. В какой методе вы работаете и почему его выбрали
          </Typography>
          <Controller
            control={control}
            name="about_yourself"
            render={({ field }) => (
              // TODO: fix textarea
              <TextArea {...field} rows={4} />
            )}
          />
        </div>
        <Button
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isMutating}
        >
          Сохранить
        </Button>
      </div>
    </FormProvider>
  );
};

export default SpecialistEditProfileMainInfo;
