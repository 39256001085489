import { EEditProfileFieldArrayNames } from '../config/enums/EFieldArrayNames';
import { TArraysFieldName } from '../config/types/TArrayFieldName';

export const educationArrayFieldNames: TArraysFieldName = {
  [EEditProfileFieldArrayNames.EDUCATION]: {
    label: 'Высшее образование',
    array: [
      { name: 'university', label: 'ВУЗ', required: true },
      {
        name: 'specialization',
        label: 'Специализация / Направление подготовки',
        required: true,
      },
      { name: 'date_from', label: 'Год окончания', type: 'number' },
    ],
    addButtonLabel: 'Добавить образование',
  },
  [EEditProfileFieldArrayNames.DEGREE]: {
    label: 'Ученая степень',
    array: [{ name: 'degree', label: 'Степень' }],
    addButtonLabel: 'Добавить ученую степень',
    max: 1,
  },
  [EEditProfileFieldArrayNames.PROFESSIONAL_RETRAINING]: {
    label: 'Профессиональная переподготовка',
    array: [
      { name: 'title', label: 'Название программы', required: true },
      {
        name: 'issuer',
        label: 'Название организации, выдавшей документ',
        required: true,
      },
      { name: 'issued_at', label: 'Год выдачи', type: 'number' },
    ],
    addButtonLabel: 'Добавить профессиональную подготовку',
  },
  [EEditProfileFieldArrayNames.ADVANCED_TRAINING]: {
    label: 'Повышение квалификации',
    array: [
      { name: 'title', label: 'Название программы', required: true },
      {
        name: 'issuer',
        label: 'Название организации, выдавшей документ',
        required: true,
      },
      { name: 'issued_at', label: 'Год выдачи', type: 'number' },
    ],
    addButtonLabel: 'Добавить повышение квалификации',
  },
  [EEditProfileFieldArrayNames.CERTIFICATES]: {
    label: 'Сертификаты',
    array: [
      { name: 'program', label: 'Название', required: true },
      {
        name: 'organization',
        label: 'Кем выдан',
        required: true,
      },
      { name: 'graduate', label: 'Год выдачи', type: 'number' },
    ],
    addButtonLabel: 'Добавить сертификат',
  },
};
