import * as yup from 'yup';

import { IMainInfo } from '../../../5_pages/SpecialistEditProfile/ui/MainInfo/interfaces/IMainInfo';

export const userInfoSchema = yup.object<IMainInfo>().shape({
  work_since: yup.string(),
  specialities: yup
    .array()
    .of(yup.string())
    .required('Должен быть выбран как минимум 1 пункт'),
  work_methods: yup
    .array()
    .of(yup.string())
    .required('Должен быть выбран как минимум 1 пункт'),
  key_themes: yup
    .array()
    .of(yup.string())
    .required('Должен быть выбран как минимум 1 пункт'),
  work_withs: yup
    .array()
    .of(yup.string())
    .required('Должен быть выбран как минимум 1 пункт'),
  about_yourself: yup.string(),
});
