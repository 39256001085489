import { Controller, Path, useFormContext } from 'react-hook-form';

import { IWorkExperienceInfo } from '../../api/interfaces/IWorkExperienceInfo';
import { CustomTypography } from '../CustomTypography';
import { Input, Typography } from '../index';

import styles from './SpecialistEditProfileInput.module.scss';

const SpecialistEditProfileInput = ({
  name,
  label,
  placeholder,
  required = false,
  type,
  disabled = false,
}: {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  const methods = useFormContext<IWorkExperienceInfo>();
  const {
    control,
    getValues,
    formState: { errors },
  } = methods;

  return (
    <div className={styles.wrapper}>
      <Typography type="description">
        {label}
        {required && <span className={styles.required_asterisk}>*</span>}
      </Typography>
      <Controller
        name={name as Path<IWorkExperienceInfo>}
        control={control}
        defaultValue={getValues(name as Path<IWorkExperienceInfo>)}
        render={({ field }) => (
          <>
            {/* @ts-ignore */}
            <Input
              {...field}
              placeholder={placeholder}
              type={type}
              disabled={disabled}
            />
            {(errors as any)?.[name] && (
              <CustomTypography type="description" className={styles.error}>
                {(errors as any)?.[name].message}
              </CustomTypography>
            )}
          </>
        )}
      />
    </div>
  );
};

export default SpecialistEditProfileInput;
