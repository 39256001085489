import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Radio } from 'antd';

import { Button, Input, Radio as CustomRadio, Typography } from '1_shared/ui';

import { ELoginStatus } from '../../../1_shared/config/enums/ELoginStatus';
import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { phoneFormConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { CustomTypography } from '../../../1_shared/ui/CustomTypography';
import { schemaLogin } from '../config/schems';
import { isPhoneField } from '../lib/checkPhone';
import useSubmit from '../model/useSubmit';

import { ILoginForm } from './interfaces/ILoginForm';

import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const {
    onSubmit,
    resetStates,
    isLogin,
    setIsLogin,
    regErrorMessage,
    loginError,
    isPasswordPage,
    loginStatus,
  } = useSubmit();
  const { email } = useParams();
  const { control, handleSubmit, formState, getValues, watch, reset } =
    useForm<ILoginForm>({
      reValidateMode: 'onChange',
      mode: 'onChange',
      resolver: yupResolver(schemaLogin(loginStatus, isPasswordPage, isLogin)),
      defaultValues: {
        login: email ?? '',
      },
    });
  watch(['role', 'login']);
  const isReg = !isLogin || isPasswordPage;

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography type="title">{`${isReg ? 'Регистрация' : 'Вход'} на платформу`}</Typography>
      <div className={styles.itemWrapper}>
        <Controller
          control={control}
          name="login"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className={styles.input}>
              <Input
                maxLength={isPhoneField(value) && !!value ? 16 : undefined}
                name="login"
                value={value}
                placeholder={!isLogin ? '+7 999 999 99 99' : undefined}
                onChange={e => {
                  const val = e.target.value;
                  if (isPhoneField(val)) {
                    resetStates(onChange, phoneFormConvert(val));
                  } else if (isLogin) {
                    resetStates(onChange, val);
                  }
                }}
                label={isLogin ? 'ТЕЛЕФОН ИЛИ ПОЧТА' : 'ТЕЛЕФОН'}
              />
              {error && (
                <CustomTypography type="description" className={styles.error}>
                  {error.message}
                </CustomTypography>
              )}
            </div>
          )}
        />
        {!isLogin && regErrorMessage && (
          <Typography type="description" className={styles.error}>
            {regErrorMessage}
          </Typography>
        )}
        {isLogin && loginError && (
          <Typography type="description" className={styles.error}>
            {loginError}
          </Typography>
        )}
        {!isPasswordPage && (
          <Controller
            control={control}
            name="role"
            render={({ field: { value, onChange } }) => (
              <Radio.Group
                value={value}
                onChange={onChange}
                className={styles.radioGroup}
              >
                <CustomRadio value={ERoles.Client}>
                  <Typography className={styles.radioBtnText}>
                    Я клиент
                  </Typography>
                </CustomRadio>
                <CustomRadio value={ERoles.Spec}>
                  <Typography className={styles.radioBtnText}>
                    Я специалист
                  </Typography>
                </CustomRadio>
              </Radio.Group>
            )}
          />
        )}
      </div>
      {loginStatus === ELoginStatus.CodeSent && !isLogin && (
        <div className={styles.itemWrapper}>
          {getValues('role') === ERoles.Client ? (
            <Controller
              control={control}
              name="nickname"
              render={({ field: { value, onChange } }) => (
                <Input value={value} onChange={onChange} label="ПСЕВДОНИМ" />
              )}
            />
          ) : (
            <div className={styles.fioWrapper}>
              <Controller
                control={control}
                name="surname"
                render={({ field: { value, onChange } }) => (
                  <Input value={value} onChange={onChange} label="ФАМИЛИЯ" />
                )}
              />
              <Controller
                control={control}
                name="firstName"
                render={({ field: { value, onChange } }) => (
                  <Input value={value} onChange={onChange} label="ИМЯ" />
                )}
              />
              <Controller
                control={control}
                name="patronymic"
                render={({ field: { value, onChange } }) => (
                  <Input value={value} onChange={onChange} label="ОТЧЕСТВО" />
                )}
              />
            </div>
          )}
        </div>
      )}
      {loginStatus === ELoginStatus.CodeSent && (
        <div className={styles.itemWrapper}>
          <Controller
            control={control}
            name="code"
            render={({ field: { value, onChange } }) => (
              <Input value={value} onChange={onChange} label="КОД" />
            )}
          />
        </div>
      )}
      {((loginStatus === ELoginStatus.NeedPassword && isLogin) ||
        isPasswordPage) && (
        <div className={styles.itemWrapper}>
          <Controller
            control={control}
            name="password"
            render={({ field: { value, onChange } }) => (
              <Input
                type="password"
                value={value}
                onChange={onChange}
                label="ПАРОЛЬ"
              />
            )}
          />
        </div>
      )}
      <div className={styles.btnWrapper}>
        <Button
          disabled={!formState.isValid}
          htmlType="submit"
          className={styles.btnLogin}
        >
          <Typography className={styles.textInBtn}>
            {isLogin || isPasswordPage ? 'ВОЙТИ' : 'ЗАРЕГИСТРИРУЙТЕСЬ'}
          </Typography>
        </Button>
        {!isPasswordPage && (
          <div className={styles.textWrapper}>
            <Typography>
              {isLogin ? 'Еще нет аккаунта?' : 'Уже есть аккаунт?'}
            </Typography>
            <button
              type="button"
              className={styles.btn}
              onClick={() => {
                setIsLogin(!isLogin);
                reset();
              }}
            >
              <Typography className={styles.btnText}>
                {isLogin ? 'Зарегистрируйтесь' : 'Войдите'}
              </Typography>
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default LoginForm;
