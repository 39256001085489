import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import useSWR from 'swr';

import { getDictionaries } from '1_shared/api/dictionary';
import useSWRWithCache from '1_shared/api/lib/useSWRWithCache';
import { getSpecById } from '1_shared/api/specialistById/specService';
import { IOption } from '1_shared/config/interfaces/IOption';
import { ISpecialistByIdData } from '1_shared/config/interfaces/ISpecialistByIdData';
import { Content, Faq, Footer, Page } from '1_shared/ui';
import { AboutSpecialist } from '2_entities';
import { Header, SpecialistAchievements, SpecialistCard } from '4_widgets';
import SpecialistPersonCard from '4_widgets/SpecialistPersonCard/ui/SpecialistPersonCard';

import { ISpecialistData } from '../../../1_shared/config/interfaces/ISpecialistData';

import styles from "./SpecialistPersonPage.module.scss";


const SpecialistPersonPage: React.FC = () => {
  const { id } = useParams();
  const { data: spec, isLoading } = useSWR(
    `/spec/specialist/${id}`,
    getSpecById,
  );
  const { data: dictionary } = useSWRWithCache<IOption[]>(
    '/spec/specialities',
    getDictionaries,
  );

  const { data: dictionaryMethods } = useSWRWithCache<IOption[]>(
    '/spec/work-methods',
    getDictionaries,
  );

  const { data: dictionaryThemes } = useSWRWithCache(
    '/spec/key-themes',
    getDictionaries,
  );

  const specTemp = useMemo(
    () =>
      ({
        ...spec,
        specialities: dictionary
          ? spec?.specialities.map(
              spec =>
                dictionary?.find((option: IOption) => option.value === spec)
                  ?.label ?? '',
            )
          : [],
        workMethods: dictionaryMethods
          ? spec?.workMethods.map(
              methd =>
                dictionaryMethods?.find(
                  (option: IOption) => option.value === methd,
                )?.label ?? '',
            )
          : [],
        keyThemes: dictionaryThemes
          ? spec?.keyThemes.map(
              themes =>
                dictionaryThemes?.find(
                  (option: IOption) => option.value === themes,
                )?.label ?? '',
            )
          : [],
      }) as ISpecialistByIdData,
    [spec, dictionary, dictionaryMethods, dictionaryThemes],
  );

  const specCard = useMemo(
    () =>
      ({
        ...specTemp,
        mediaContentResponse: {
          id: spec?.mediaContentResponse[0]?.id,
          ...spec?.mediaContentResponse[0]?.mediaContentResponse,
        },
      }) as ISpecialistData,
    [specTemp, spec],
  );

  if (isLoading) {
    return (
      <Page>
        <Header />
        <Content>
          <Spin indicator={<LoadingOutlined spin />} />
        </Content>
      </Page>
    );
  }

  return (
    <Page>
      <Header />
      <Content wrapperClass={styles.content}>
        <SpecialistPersonCard spec={specTemp} />
      </Content>
      <Content classNames="bg_lightBlue">
        <SpecialistAchievements spec={specTemp} />
      </Content>
      <AboutSpecialist spec={specTemp} />
      <Content>
        <SpecialistCard spec={specCard} dark />
      </Content>
      <Faq />
      <Footer />
    </Page>
  );
};

export default SpecialistPersonPage;
