import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Radio } from 'antd';
import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';

import { ESex } from '../../../../1_shared/config/enums/ESex';
import { IUserEditInfo } from '../../../../1_shared/config/interfaces/IUserEditInfo';
import { userEditInfoSchema } from '../../../../1_shared/config/validationSchemas/userInfoSchema';
import { phoneFormConvert } from '../../../../1_shared/lib/helpers/phoneConvert';
import {
  Button,
  DatePicker,
  Input,
  MaskInput,
  Typography,
  Upload,
} from '../../../../1_shared/ui';
import { CustomTypography } from '../../../../1_shared/ui/CustomTypography';
import { useAuth } from '../../../../app/module/lib/hooks/useAuth';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import {
  userObjectConverterCredentials,
  userObjectConverterToNew,
} from '../../helpers/userObjectConverter';
import { editProfileCredentials } from '../../model/service/specEditService';

import styles from './SpecialistEditProfileCredentials.module.scss';

const SpecialistEditProfileCredentials = () => {
  const { user, setSpecUser } = useAuth();

  const methods = useForm<IUserEditInfo>({
    // @ts-ignore TODO: fix resolver
    resolver: yupResolver<IUserEditInfo>(userEditInfoSchema),
  });
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = methods;
  watch();

  const { trigger, isMutating, error } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileCredentials,
  );

  const onSubmit = async (data: IUserEditInfo) => {
    await trigger({
      ...data,
      date_of_birth: dayjs(data?.date_of_birth).isValid()
        ? dayjs(data?.date_of_birth).unix()
        : data?.date_of_birth,
      phone: !data?.phone
        ? data?.phone
        : data?.phone.replace('+', '').split(' ').join(''),
    });
    if (data && !error) {
      setSpecUser({
        ...user,
        ...Object.fromEntries(
          Object.entries(userObjectConverterToNew(data)).filter(
            el => !!el?.[1],
          ),
        ),
      });
    }
  };

  useEffect(() => {
    reset(userObjectConverterCredentials(user));
  }, [user]);

  return (
    <FormProvider {...methods}>
      <div className={styles.root}>
        <Typography type="title">Личные данные</Typography>
        <div className={styles.imagesWrap}>
          <Controller
            control={control}
            name="avatar"
            render={({ field: { value, onChange } }) => (
              <Upload
                disabled
                maxCount={1}
                accept=".png, .jpg, .jpeg, .webp"
                onChange={info => {
                  if (info.file.status === 'removed') {
                    onChange(null);
                  } else {
                    onChange(info.file);
                  }
                }}
                fileList={value ? [value] : []}
                customRequest={({ onSuccess }) => onSuccess && onSuccess('ok')}
                listType="picture-card"
              />
            )}
          />
          <Typography type="description">Аватар</Typography>
        </div>
        <div>
          <Typography type="description">
            Имя<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="first_name"
            control={control}
            defaultValue={getValues('first_name')}
            render={({ field }) => (
              <>
                <Input {...field} placeholder="Имя" />
                {errors.first_name && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.first_name.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>
        <div>
          <Typography type="description">
            Фамилия<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="last_name"
            control={control}
            defaultValue={getValues('last_name')}
            render={({ field }) => (
              <>
                <Input {...field} placeholder="Фамилия" />
                {errors.last_name && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.last_name.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>
        <div>
          <Typography type="description">Отчество</Typography>
          <Controller
            name="patronymic"
            control={control}
            defaultValue={getValues('patronymic')}
            render={({ field }) => <Input {...field} placeholder="Отчество" />}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            E-mail адрес<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="email"
            control={control}
            defaultValue={getValues('email')}
            render={({ field }) => (
              <>
                <Input {...field} placeholder="e-mail" />
                {errors.email && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.email.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Номер телефона<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="phone"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <MaskInput
                  size="middle"
                  value={value}
                  autocomplete="on"
                  onChange={e => {
                    const maskPhone = phoneFormConvert(e.target.value);
                    onChange(maskPhone);
                  }}
                  mask="+7 999 999 99 99"
                  placeholder="Телефон"
                />
                {errors.phone && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.phone.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Пол<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="sex"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Radio.Group
                value={value}
                onChange={onChange}
                className={styles.sexGroup}
              >
                <Radio value={ESex.Male} className={styles.unselect}>
                  Мужской
                </Radio>
                <Radio value={ESex.Female} className={styles.unselect}>
                  Женский
                </Radio>
                <Radio value="" className={styles.unselect}>
                  Не указано
                </Radio>
              </Radio.Group>
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Дата рождения<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="date_of_birth"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <DatePicker
                  inputReadOnly
                  value={value ? dayjs(value) : null}
                  maxDate={dayjs()}
                  onChange={value =>
                    onChange(value ? dayjs(value).valueOf() : null)
                  }
                />
                {errors.date_of_birth && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.date_of_birth.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
          <div className={styles.uploadWrap}>
            <Typography type="title">Ваши медиа-файлы</Typography>
            <Controller
              control={control}
              name="media"
              render={({ field: { value, onChange } }) => (
                <Upload
                  disabled
                  className="mediaWrapp"
                  accept=".png, .jpg, .jpeg, .webp"
                  onChange={info => {
                    onChange(info.fileList);
                  }}
                  fileList={value || []}
                  customRequest={({ onSuccess }) =>
                    onSuccess && onSuccess('ok')
                  }
                  listType="picture-card"
                />
              )}
            />
          </div>
        </div>
        <Button
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isMutating}
        >
          Сохранить
        </Button>
      </div>
    </FormProvider>
  );
};

export default SpecialistEditProfileCredentials;
