import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import cn from 'classnames';
import useSWR from 'swr';

import { getSpecById } from '1_shared/api/specialistById/specService';
import { timeConfig } from '1_shared/config/timeConfig';
import {
  Breadcrumbs,
  Button,
  CheckboxCircle,
  Input,
  Typography,
} from '1_shared/ui';
import { SpecialistAvatar } from '2_entities';
import { EnterCode, PhoneOrderInput, SlotSelect } from '3_features';

import pdf_offerAgreement from '../../../1_shared/assets/docs/Договор_клиентский_оферты_ООО_ПКО.pdf';
import pdf_dataProcessing from '../../../1_shared/assets/docs/Договор_Обработки_персональных_данных_специалист_DoTherapy.pdf';
import pdf_userAgreement from '../../../1_shared/assets/docs/Пользовательское_соглашение_DoTherapy.pdf';
import { DurationType } from '../../../1_shared/config/interfaces/DurationType';
import { ISlot } from '../../../1_shared/config/interfaces/ISlot';
import { ISpecialistShortData } from '../../../1_shared/config/interfaces/ISpecialistShortData';
import { RoutePath } from '../../../1_shared/config/routes';
import { ELoginStatus } from '../../../1_shared/config/enums/ELoginStatus';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { configMedia } from '../../SpecialistCard/ui/config/configMedia';
import { schema } from '../config/schema';
import usePayment from '../model/usePayment';

import { IApplicationForm } from './interface/IApplicationForm';
import usePhoneLogin from './model/usePhoneLogin';

import styles from './OrderForm.module.scss';

const breadCrumbs = [
  {
    title: (
      <Link to={RoutePath.MAIN}>
        <Typography type="description">DOTHERAPY</Typography>
      </Link>
    ),
  },
  {
    title: (
      <Link to={RoutePath.SPECIALISTS}>
        <Typography type="description">СПЕЦИАЛИСТЫ</Typography>
      </Link>
    ),
  },
  {
    title: (
      <Typography type="description">ЗАПИСЬ НА ПРИЕМ</Typography>
    ),
  },
];

const OrderForm = (): React.ReactElement => {
  const { state } = useLocation();

  const { data: spec, isLoading } = useSWR(
    `/spec/specialist/${(state as any)?.id}`,
    getSpecById,
  );

  const {
    openCode,
    setOpenCode,
    status,
    foundUser,
    handleChangePhone,
    registerUser,
    updateNickname,
    setIsLogin,
    setStatus,
    isLogin,
    errorTimerMessage,
    setErrorTimerMessage,
  } = usePhoneLogin();

  const {
    payment,
    onPromoCodeFind,
    promoCode,
    isPromoLoading,
    errorPromo,
    setPromoCode,
  } = usePayment(updateNickname);

  const { user, role } = useAuthContext();
  const methods = useForm<IApplicationForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      slotId:
        (state as any)?.slot?.slotId ?? spec?.slots[0]?.slotId ?? undefined,
    },
  });

  const { control, reset, handleSubmit, watch, getValues, setValue } = methods;
  watch(['slotId', 'login', 'name', 'promoCode', 'code']);
  const agreementTerms = useWatch({ control, name: 'agreementTerms' });
  const agreementDataProcessing = useWatch({
    control,
    name: 'agreementDataProcessing',
  });

  useEffect(() => {
    if (status === ELoginStatus.NotFound && getValues('login')) {
      (async () => await registerUser(getValues('login') || ''))();
    }
  }, [status]);

  useEffect(() => {
    reset({
      login: user?.phone || '',
      name:
        user?.nickname && user?.nickname !== 'Аноним'
          ? user?.nickname
          : undefined,
      code: undefined,
    });
    if (user) {
      setStatus(ELoginStatus.None);
      setIsLogin(true);
      setErrorTimerMessage(null);
    }
  }, [user, reset, role, setStatus, setIsLogin, setErrorTimerMessage]);

  const slot = useMemo(
    () =>
      spec?.slots?.find(slot =>
        getValues('slotId')
          ? slot.slotId === getValues('slotId')
          : (slot.slotId === (state as any)?.slot?.slotId ??
            spec?.slots[0]?.slotId),
      ),
    [getValues('slotId'), spec?.slots, state],
  );

  const onSubmit = async (data: IApplicationForm) => {
    await payment(
      Number(slot?.cost),
      data,
      spec as ISpecialistShortData,
      slot as ISlot,
    );
  };

  return (
    <div className={styles.root}>
      <div>
        <Breadcrumbs items={breadCrumbs} />
        <Typography type="h2">Запись на прием</Typography>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
          <div onSubmit={handleSubmit(onSubmit)} className={styles.formGrid}>
            <div className={styles.row}>
              <Typography type="subtitle" className={styles.rowText}>
                СПЕЦИАЛИСТ
              </Typography>
              {isLoading ? (
                <Spin />
              ) : (
                <SpecialistAvatar
                  firstName={String(spec?.firstName)}
                  lastName={String(spec?.secondName)}
                  imgPreview={String(
                    spec?.mediaContentResponse[0]?.mediaContentResponse
                      .previewLink ??
                      configMedia.mediaContentResponse.previewLink,
                  )}
                />
              )}
            </div>
            <Controller
              control={control}
              name="slotId"
              render={({ field }) => {
                const duration = spec?.slots.find(
                  el => el.slotId === field.value,
                )?.duration;
                return (
                  <div className={styles.row}>
                    <Typography type="subtitle" className={styles.rowText}>
                      ДАТА И ВРЕМЯ
                    </Typography>

                    <SlotSelect slots={spec?.slots ?? []} {...field} />
                    {duration && (
                      <Typography type="description">
                        {`Длительность сеанса ${timeConfig[duration as DurationType]}`}
                      </Typography>
                    )}
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name="login"
              render={({ field: { value, onChange } }) => (
                <PhoneOrderInput
                  user={user}
                  status={status}
                  value={value}
                  onChange={onChange}
                  isLogin={isLogin}
                  errorTimerMessage={errorTimerMessage}
                  setStatus={setStatus}
                  setOpenCode={setOpenCode}
                  setIsLogin={setIsLogin}
                  setErrorTimerMessage={setErrorTimerMessage}
                  handleChangePhone={handleChangePhone}
                  foundUser={foundUser}
                />
              )}
            />
            {(user || (!isLogin && status === ELoginStatus.CodeSended)) && (
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState }) => (
                  <div className={styles.row}>
                    <Typography type="subtitle" className={styles.rowText}>
                      ИМЯ ИЛИ ПСЕВДОНИМ
                    </Typography>
                    <Input
                      className={cn({ [styles.activeInput]: !field?.value })}
                      disabled={!!user?.nickname && user?.nickname !== 'Аноним'}
                      size="middle"
                      status={fieldState.error ? 'error' : ''}
                      {...field}
                    />
                    <Typography type="description">
                      Можете указать псевдоним, если не хотите раскрывать имя.
                      Ваши данные строго конфиденциальны
                    </Typography>
                  </div>
                )}
              />
            )}

            <div className={styles.row}>
              <Typography type="subtitle" className={styles.rowText}>
                ПРОМОКОД
              </Typography>
              <Controller
                control={control}
                name="promoCode"
                render={({ field }) => (
                  <div className={styles.columnBtn}>
                    <Input
                      size="middle"
                      disabled={!!promoCode || !user}
                      {...field}
                      suffix={
                        !promoCode ? (
                          <Button
                            type="text"
                            disabled={
                              !field.value || !getValues('slotId') || !user
                            }
                            onClick={() =>
                              onPromoCodeFind({
                                name: String(field.value),
                                slotId: getValues('slotId'),
                                clientId: user?.id as string,
                              })
                            }
                          >
                            <Typography
                              className={styles.codeBtn}
                              type="description"
                            >
                              {!isPromoLoading ? `ПРИМЕНИТЬ` : <Spin />}
                            </Typography>
                          </Button>
                        ) : (
                          <Button
                            type="text"
                            disabled={
                              !field.value || !getValues('slotId') || !user
                            }
                            onClick={() => {
                              setPromoCode(undefined);
                              field.onChange('');
                            }}
                          >
                            <Typography
                              className={styles.codeBtn}
                              type="description"
                            >
                              ИЗМЕНИТЬ
                            </Typography>
                          </Button>
                        )
                      }
                    />
                    <Typography type="description" className={styles.error}>
                      {errorPromo}
                    </Typography>
                  </div>
                )}
              />
              {promoCode ? (
                <Typography type="description">
                  Ваша скидка - {promoCode.discount}
                </Typography>
              ) : (
                <Typography type="description">
                  Код из подарочного сертификата тоже сюда
                </Typography>
              )}
            </div>
            <div className={styles.row}>
              <Typography type="subtitle" className={styles.rowText}>
                СОГЛАСИЯ
              </Typography>
              <div className={styles.checkboxWrapper}>
                <Controller
                  control={control}
                  name="agreementTerms"
                  render={({ field }) => (
                    <CheckboxCircle {...field}>
                      Принять{' '}
                      <a
                        href={pdf_userAgreement}
                        target="_blank"
                        data-title="A new page"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        пользовательское соглашение
                      </a>{' '}
                      и{' '}
                      <a
                        href={pdf_offerAgreement}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        договор оферты
                      </a>
                    </CheckboxCircle>
                  )}
                />
                <Controller
                  control={control}
                  name="agreementDataProcessing"
                  render={({ field }) => (
                    <CheckboxCircle {...field}>
                      Согласие на{' '}
                      <a
                        href={pdf_dataProcessing}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        обработку данных
                      </a>
                    </CheckboxCircle>
                  )}
                />
              </div>
            </div>
          </div>
          {slot?.cost ? (
            <div className={styles.btnWrapper}>
              <Typography type="subtitle" className={styles.rowText}>
                ИТОГО
              </Typography>
              <div className={styles.costWrapper}>
                <Typography type="title" className={styles.rowText}>
                  {`${promoCode && slot ? slot.cost - promoCode.discount : slot?.cost} ₽`}
                </Typography>
                <Button
                  disabled={
                    status !== ELoginStatus.None ||
                    !user ||
                    !getValues('name') ||
                    !agreementTerms ||
                    !agreementDataProcessing
                  }
                  htmlType="submit"
                  className={styles.btn}
                >
                  ОПЛАТИТЬ
                </Button>
              </div>
              <Typography type="description">
                После оплаты мы пришлем
                <br />
                вам доступ на платформу
              </Typography>
            </div>
          ) : (
            <Typography type="textM" className={styles.error}>
              У специалиста не указана цена для слота.
            </Typography>
          )}
        </form>
        <EnterCode
          setStatus={setStatus}
          open={openCode}
          isLogin={isLogin}
          onClose={() => {
            setOpenCode(!openCode);
            setValue('code', undefined);
          }}
          control={control}
          nickName={getValues('name') || ''}
          name="code"
          phone={getValues('login') || ''}
          foundUser={foundUser}
          registerUser={registerUser}
        />
      </FormProvider>
    </div>
  );
};

export default OrderForm;
