import { FormProvider, useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';

import { EEditProfileFieldArrayNames } from '../../../../1_shared/config/enums/EFieldArrayNames';
import { Button as UiButton, Typography } from '../../../../1_shared/ui';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import { editProfileEducation } from '../../model/service/specEditService';

import EducationComponent from './ui/HighEducation/EducationComponent';

import styles from './SpecialistEditProfileEducation.module.scss';

const SpecialistEditProfileEducation = () => {
  // TODO: типизация формы
  const methods = useForm();
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { trigger, isMutating } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileEducation,
  );

  // TODO: any заменить на тип согласно форме
  const onSubmit = async (data: any) => {
    await trigger({
      ...data,
    });
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.wrapper}>
        <Typography type="title">Образование</Typography>
        {Object.values(EEditProfileFieldArrayNames).map((fieldName: string) => (
          <EducationComponent fieldArrayName={fieldName} />
        ))}
        {isValid && (
          <UiButton
            className={styles.saveButton}
            type="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isMutating}
          >
            Сохранить
          </UiButton>
        )}
      </div>
    </FormProvider>
  );
};

export default SpecialistEditProfileEducation;
