import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { RcFile } from 'antd/es/upload';
import cn from 'classnames';

import { specApiInstance } from '1_shared/api/apiInstance';
import { ESpecStatus } from '1_shared/config/enums/ESpecStatus';
import { RoutePath } from '1_shared/config/routes';

import { firstFormTabsSchema } from '../../../1_shared/config/validationSchemas/firstFormTabsSchema';
import { Button, Tabs } from '../../../1_shared/ui';
import { IFirstFormSpec } from '../../../1_shared/config/interfaces/IFirstFormSpec';
import useGetTabItems from '../module/useGetTabItems';

import styles from './FirstFormTabs.module.scss';

const FirstFormTabs = () => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const methods = useForm({
    mode: 'onChange',
    // @ts-ignore TODO: fix resolver
    resolver: yupResolver<IFirstFormSpec>(firstFormTabsSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const tabItems = useGetTabItems(setActiveTab);

  const navigate = useNavigate();

  const goPrevious = () => {
    setActiveTab('1');
  };

  const submitBaseFormData = async (data: IFirstFormSpec['data']) => {
    const response = await specApiInstance.post(
      '/spec/specialist/fill-form',
      data,
    );
    return response.data;
  };

  const uploadAvatar = async (avatar: RcFile, specialistId: string) => {
    try {
      const formData = new FormData();
      formData.append('avatar', avatar);
      const response = await specApiInstance.post(
        `/content/specialist-content/upload/avatar?specialistId=${specialistId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error('Avatar error');
    }
  };

  const uploadMediaFiles = async (
    mediaFiles: RcFile[],
    specialistId: string,
  ) => {
    try {
      const formData = new FormData();
      mediaFiles.forEach(file => {
        formData.append('contentFiles', file);
      });
      const response = await specApiInstance.post(
        `/content/specialist-content/multiple/upload-by-param?specialistId=${specialistId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error('Media error');
    }
  };
  const onSubmit = async (data: any) => {
    const user = JSON.parse(String(localStorage.getItem('user')));
    const specialistId = user.id;
    if (data.avatar) {
      try {
        await uploadAvatar(data.avatar.originFileObj as RcFile, specialistId);
      } catch (avatarError) {
        console.error('Avatar error:', avatarError);
        return;
      }
    }

    if (data.mediaFiles && data.mediaFiles.length > 0) {
      try {
        await uploadMediaFiles(
          data.mediaFiles.map((file: any) => file.originFileObj as RcFile),
          specialistId,
        );
      } catch (mediaError) {
        console.error('Media error:', mediaError);
        return;
      }
    }

    try {
      await submitBaseFormData(data.data);
    } catch (baseDataError) {
      console.error('Base data error:', baseDataError);
      return;
    }

    const profileResponse = await specApiInstance.get(
      `/spec/specialist/profile`,
    );
    localStorage.setItem('user', JSON.stringify(profileResponse.data));
    // setSpecUser(response.data);
    // navigate(RoutePath.MAIN);
    if (profileResponse.data.status === ESpecStatus.NotActive) {
      navigate(RoutePath.FIRST_FORM);
    } else {
      navigate(RoutePath.MAIN);
    }
  };

  return (
    <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Tabs
          classNameTabs={styles.tabs}
          items={tabItems}
          disabledToggle
          activeTab={activeTab}
          defaultActiveTabIndex="1"
        />
        <footer
          className={cn(styles.btnWrapper, {
            [styles.btnFirstWrap]: tabItems && activeTab === tabItems[0].key,
          })}
        >
          {tabItems && activeTab !== tabItems[0].key && (
            <Button
              className={styles.btnPrevios}
              type="secondary"
              onClick={goPrevious}
            >
              НАЗАД
            </Button>
          )}
          {tabItems && activeTab === tabItems[tabItems.length - 1].key && (
            <Button
              htmlType="submit"
              className={styles.btnSubmit}
              type="primary"
              disabled={!isValid}
            >
              ОТПРАВИТЬ
            </Button>
          )}
        </footer>
      </FormProvider>
    </form>
  );
};

export default FirstFormTabs;
