import { ReactElement } from 'react';
import { Image } from 'antd';
import cn from 'classnames';

import DoTherapyLogo from '1_shared/assets/images/doTherapyLogo.png';
import { EMediaType } from '1_shared/config/enums/EMediaType';

import { IPreviewImage } from './interfaces/IPreviewImage';

import styles from './PreviewImage.module.scss';

const PreviewImage = ({
  media,
  dark = false,
  noAvatar = false,
  className = '',
  showPreview = false,
  ...others
}: IPreviewImage): ReactElement => {
  const { mediaType, mainMediaLink, previewLink } = media;

  return (
    <div
      className={cn(styles.root, {
        [className]: className !== '',
        [styles.darkRoot]: dark,
        [styles.noAvatar]: noAvatar,
      })}
    >
      <Image
        {...others}
        className={cn(styles.antImage, { [styles.antImageLogo]: !previewLink })}
        preview={
          (mediaType === EMediaType.Video && {
            // eslint-disable-next-line react/no-unstable-nested-components
            imageRender: () => (
              <video muted width="100%" controls src={mainMediaLink} />
            ),
          }) ||
          showPreview
        }
        src={previewLink ?? DoTherapyLogo}
        style={
          !previewLink
            ? { width: 'inherit', borderRadius: '0 !important' }
            : undefined
        }
      />
    </div>
  );
};

export default PreviewImage;
