import { Controller, Path, useFormContext } from 'react-hook-form';
import { Checkbox } from 'antd';

import { IWorkExperienceInfo } from '../../api/interfaces/IWorkExperienceInfo';
import { CustomTypography } from '../CustomTypography';
import { Typography } from '../index';

import styles from './SpecialistEditProfileInput.module.scss';

const SpecialistEditProfileCheckbox = ({
  name,
  label,
  required = false,
}: {
  name: string;
  label?: string;
  required?: boolean;
}) => {
  const methods = useFormContext<IWorkExperienceInfo>();
  const {
    control,
    getValues,
    formState: { errors },
  } = methods;

  return (
    <div className={styles.checkbox}>
      <Controller
        name={name as Path<IWorkExperienceInfo>}
        control={control}
        defaultValue={getValues(name as Path<IWorkExperienceInfo>)}
        render={({ field }) => (
          <>
            <Checkbox {...field}>
              <Typography type="description" className={styles.checkboxLabel}>
                {label}
                {required && (
                  <span className={styles.required_asterisk}>*</span>
                )}
              </Typography>
            </Checkbox>
            {(errors as any)?.[name] && (
              <CustomTypography type="description" className={styles.error}>
                {(errors as any)?.[name].message}
              </CustomTypography>
            )}
          </>
        )}
      />
    </div>
  );
};

export default SpecialistEditProfileCheckbox;
